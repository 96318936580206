<template>
    <div class="profile__detail">

        <img src="@/assets/notification/ic_notification_delete.svg" alt="Delete Account">
        <h3>Konto löschen</h3>
        <p>Bist du sicher, dass du deinen Account endgültig und unwiderruflich löschen möchtest?</p>
        
        <!-- TODO @Marco: Das Eingabefeld soll nur erscheinen, wenn die Passworteingabe wegen Firebase notwendig ist. -->
        <v-text-field v-show="retryWithPassword" v-model="password" placeholder="Passwort" type="password" required>
        </v-text-field>

        <v-btn @click="deleteUserCheck" class="button button--danger">Ja, Konto löschen</v-btn>
    </div>
</template>
<script>
import firebase from 'firebase';
import {db, devicesCollection, storage} from '@/firebase/index.js';

export default {
    name: 'SettingsDelete',
    mounted() {
        this.init();
    },
    data() {
        return {
            user: null,
            retryWithPassword: false,
            password: null,
            providerId: null,
        }
    },
    methods: {
        init() {
            this.user = firebase.auth().currentUser;
            if (this.user) {
                this.providerId = this.user.providerData[0].providerId
            }
        },
        signInAgain() {
            let provider;
            if (this.providerId === "google.com")
                provider = new firebase.auth.GoogleAuthProvider();
            else if (this.providerId === "facebook.com")
                provider = new firebase.auth.FacebookAuthProvider();
            else if (this.providerId === "apple.com") {
                provider = new firebase.auth.OAuthProvider('apple.com');
                provider.addScope('email');
                provider.addScope('name');
            }
            firebase.auth().signInWithPopup(provider).then(() => {
                this.deleteUserAndData();
            });
        },
        deleteUserCheck() {
            if (this.retryWithPassword && (this.providerId == "password")) {
                var credentials = firebase.auth.EmailAuthProvider.credential(
                    this.user.email,
                    this.password
                );
                this.user.reauthenticateWithCredential(credentials).then( () => this.deleteUserAndData() );
            }
            else {
                this.deleteUserAndData();
            }
        },
        async deleteUserAndData() {
            let userId = this.user.uid;

            // deleting fitness plan, this must happen first before logout
            await storage.ref("fitnessplan").child(userId).delete().then(() => {
                // File deleted successfully
                console.log("deleted user fitness plan");
            }).catch((error) => {
                // Uh-oh, an error occurred!
                console.log("error deleting fitnessplan entry for user: " + error);
            });
        
            this.user.delete().then(() => {
                this.$store.dispatch('userSignOut');
                // delete refs: 
                let collection_list = ["userFeedback", "userInformation", "userPlaylists", "favourites"];
                collection_list.forEach( (c) => this.deleteRefs(c, userId) );

                devicesCollection.doc(userId).delete();

                alert("Dein Account wurde gelöscht! Du wirst nun ausgeloggt.");
            }).catch((error) => {
                console.log(error);
                if (this.providerId == "password") {
                    this.retryWithPassword = true;
                }
                else {
                    this.signInAgain();
                }
            });
        },
        deleteRefs(collectionName, userId) {
            console.log("deleting user documents from " + collectionName);  
            var delete_query = db.collection(collectionName).where('userId', '==', userId);
            delete_query.get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    doc.ref.delete();
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .profile__detail {
        text-align: center;
        margin-top: 0;
    }

    img {
        margin-top: auto;
        margin-bottom: 29px;
        max-height: 128px;
    }

    h3 {
        margin-bottom: 11px;
    }

    p {
        line-height: 20px;
    }

    .v-text-field {
        margin-top: 19px !important;
    }
</style>